import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { css } from '@emotion/core';

import { rhythm } from '../utils/typography';
import Image from 'gatsby-image';
import TagIcon from './TagIcon';

const PostList = ({ posts }) => {
    return (
        <div css={css(containerStyle)}>
            {posts.map(({ node }) => {
                const title = node.frontmatter.title || node.fields.slug;

                const { cover, tags } = node.frontmatter;
                return (
                    <div css={css(itemContainerStyle)} key={node.fields.slug}>
                        <Link style={{ color: 'inherit', boxShadow: `none` }} to={node.fields.slug}>
                            <div css={css(itemStyle)}>
                                <div css={css(imgContainerStyle)}>
                                    {cover ? (
                                        <Image
                                            fixed={cover.childImageSharp.fixed}
                                            alt={title}
                                            css={css(imageStyle)}
                                            style={{ position: 'absolute', height: '100%' }}
                                        />
                                    ) : (
                                        <div css={css(imageStyle)}></div>
                                    )}
                                </div>
                                <div css={css(stringContainerSyle)}>
                                    <h3 css={css(titleStyle, titleClampStyle)}>{title}</h3>
                                    <div css={css(dateTagStyle)}>
                                        <small>{node.frontmatter.date}</small>
                                        <TagIcon tags={tags} />
                                    </div>
                                    <div>
                                        <p
                                            css={css(descStyle, clampLineStyle)}
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    node.frontmatter.description ||
                                                    node.excerpt.slice(0, 100),
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                );
            })}
        </div>
    );
};

PostList.propTypes = {
    posts: PropTypes.array,
};

export default PostList;

const titleStyle = {
    marginTop: rhythm(1 / 4),
    marginBottom: rhythm(1 / 4),
};

const containerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
};

const itemContainerStyle = {
    width: '33%',
    padding: '16px',
    '@media only screen and (max-width: 768px)': {
        width: '50%',
    },
    '@media only screen and (max-width: 489px)': {
        width: '100%',
    },
};

const itemStyle = {
    background: '#fbfbfb',
    boxShadow: '0 20px 20px -20px rgba(0, 0, 0, 0.3), 0 0 15px rgba(0, 0, 0, 0.06)',
    maxWidth: '100%',
    overflow: 'hidden',
    transition: 'box-shadow 0.3s ease-in-out',
    '&:hover': {
        boxShadow: '0 20px 30px -20px rgba(0, 0, 0, 0.3), 0 0 25px 5px rgba(0, 0, 0, 0.06)',
    },
};

const imgContainerStyle = {
    overflow: 'hidden',
    paddingTop: '100%',
    position: 'relative',
};

const imageStyle = {
    width: '100%',
    minWidth: '100%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
};

const stringContainerSyle = {
    padding: `${rhythm(1 / 2)} ${rhythm(1 / 3)}`,
};

const descStyle = {
    marginBottom: rhythm(1 / 4),
};

const titleClampStyle = `
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;

const clampLineStyle = `
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
`;

const dateTagStyle = {
    display: 'flex',
    justifyContent: 'space-between',
};
