import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

import Comic from '../../content/assets/comic.inline.svg';
import Painting from '../../content/assets/painting.inline.svg';

const TagIcon = ({ tags }) => {
    const isComic = tags.indexOf('漫畫') > -1;
    const isPaint = tags.indexOf('插畫') > -1;
    return (
        <>
            {isComic && <Comic css={css(iconStyle)} />}
            {isPaint && <Painting css={css(iconStyle)} />}
        </>
    );
};

TagIcon.propTypes = {
    tags: PropTypes.array,
};

export default TagIcon;

const iconStyle = {
    width: '24px',
    height: '24px',
};
