import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { css } from '@emotion/core';

import Bio from '../components/bio';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PostList from '../components/PostList';

import { rhythm } from '../utils/typography';

const BlogIndex = ({ data, location }) => {
    const siteTitle = data.site.siteMetadata.title;
    const posts = data.allMarkdownRemark.edges;
    const { social, author } = data.site.siteMetadata;
    const ogImage = data.imageSharp.fluid.src;

    return (
        <Layout location={location} title={siteTitle} social={social} author={author}>
            <SEO title={siteTitle} description="linda 創作空間" image={ogImage} />
            <div css={css(containerStyle)}>
                <PostList posts={posts} />
            </div>
            <div css={css(authorStyle)}>
                <Bio />
            </div>
        </Layout>
    );
};

BlogIndex.propTypes = {
    data: PropTypes.object,
    location: PropTypes.object,
};

export default BlogIndex;

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
                author {
                    name
                    summary
                }
                social {
                    facebook
                    twitter
                    instagram
                }
            }
        }
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
            edges {
                node {
                    excerpt(pruneLength: 400)
                    fields {
                        slug
                    }
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        title
                        description
                        tags
                        cover {
                            childImageSharp {
                                fixed(width: 400, height: 300) {
                                    ...GatsbyImageSharpFixed
                                }
                            }
                        }
                    }
                }
            }
        }
        imageSharp(fluid: { src: { regex: "/ogImage.jpg/" } }) {
            fluid {
                src
            }
        }
    }
`;

const containerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    width: '95%',
    margin: '0 auto',
    '@media only screen and (max-width: 768px)': {
        width: '100%',
    },
};

const authorStyle = {
    marginTop: rhythm(2),
};
